import { useState, useRef } from "react";
import useTracking from "../hooks/useTracking";
//import themeConfig from '../theme/config.json'
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

const verifyToken = async (token) => {
  try {
    const config = {
      method: "post",
      url: `${process.env.REACT_APP_API_URL}token`,
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY_TRACKING,
        "content-type": "application/json",
      },
      data: { token },
    };
    const respuesta = await axios(config);
    return respuesta.data;
  } catch (error) {
    console.log("error ", error);
  }
};

const Formulario = () => {
  //const recaptchaRef = useRef(null);
  const { setAlerta, busquedaTracking } = useTracking();

  const [busqueda, setBusqueda] = useState({
    tracking: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(busqueda).includes("")) {
      setAlerta("Introduce el código de seguimiento");
      return;
    }

    // const token = recaptchaRef.current.getValue();
    // if (token && token !== "") {
    //   const valid_token = await verifyToken(token);
    //   if (valid_token.success) {
         busquedaTracking(busqueda);
         busqueda.tracking = "";
    //     recaptchaRef.current.reset();
    //   } else {
    //     setAlerta("Captcha inválido");
    //   }
    // } else {
    //   setAlerta("Debe confirmar el captcha");
    // }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="legend title">CONSULTAR EL ESTADO DE TU PEDIDO</div>
      {/*<div className="legend subtitle">Ingresa el código de seguimiento que llegó a tu correo</div>*/}

      <div className="form-grid">
        <div>
          <input
            type="text"
            name="tracking"
            placeholder="Introduce el código de seguimiento"
            value={busqueda.tracking}
            onChange={(e) =>
              setBusqueda({
                ...busqueda,
                [e.target.name]: e.target.value,
              })
            }
          />
        </div>
        <div>
          <input type="submit" value="Buscar" />
        </div>
      </div>
      {/* <div className="captcha">
        <div></div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          size="normal"
        />
        <div></div>
      </div> */}
    </form>
  );
};

export default Formulario;
