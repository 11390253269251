import themeConfig from '../../../theme/config.json'

const staticPath = process.env.REACT_APP_STATICS;
const theme = process.env.REACT_APP_THEME;

const Logo = () => {
    const pathLogo = `${staticPath}/${themeConfig.logo.File}`
  return (
    <div>
      <img width={themeConfig.logo.Width} src={pathLogo} alt={themeConfig.name} />
    </div>
  );
};

export default Logo;
