
import Alerta from "./Alerta";
import Listado from "./Listado";
import Loading from "./Loading";
import useTracking from "../hooks/useTracking";


const AppTracking = () => {
  const { alerta, listado, cargando } = useTracking();
  return (
    <>
      {cargando && <Loading />}
      {!cargando && alerta && (
        <main>
          <Alerta>{alerta}</Alerta>
        </main>
      )}
      {!cargando && !alerta && JSON.stringify(listado).length > 2 && (
        <main>
          <Listado />
        </main>
      )}
    </>
  );
};

export default AppTracking;
