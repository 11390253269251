import AppTracking from "../components/AppTracking";
import { TrackingProvider } from "../context/TrackingProvider";
import Formulario from "../components/Formulario";

const App = () => {
  return (
    <TrackingProvider>
      <Formulario />
      <AppTracking />
    </TrackingProvider>
  );
};

export default App;
