export const FormatFecha = (fecha) => {
    var event = new Date(fecha);
    var options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return <div>{event.toLocaleDateString("es-ES", options)}</div>;
  };

