import Logo from "../logo";
import themeConfig from '../../../theme/config.json'

export const Header = () => {
  return (
    <header style={{backgroundColor:`${themeConfig.themeColors.header.backgroundColor}`}}>
      <Logo />
    </header>
  );
};

export default Header