import { useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();
  return (
    <div>
      <h2 className="text-center">Ocurrió un error inesperado:</h2>
      <h5 className="text-center">{error.message}</h5>
    </div>
  );
};

export default ErrorPage;
