import { useState, createContext } from "react";
import axios from "axios";

const TrackingContext = createContext();

const TrackingProvider = ({ children }) => {

  const [alerta, setAlerta] = useState('')
  const [listado, setListado] = useState({})
  const [cargando, setCargando] = useState(false)

  const busquedaTracking = async (busqueda) => {
    setCargando(true)
    try{
      const { tracking } = busqueda
      const header = {
        headers: {
            "X-API-KEY": process.env.REACT_APP_API_KEY_TRACKING,
            "content-type": "application/json"
        }
    };
      const url = `${process.env.REACT_APP_API_URL}seguimiento?tracking=${tracking}`
      
      const result = await axios.get(url, header);

      if (result.status === 204){
        setAlerta('No se encontró código de seguimiento')
      }
      else if (result.status === 200){
        setListado(result.data)
        setAlerta('')
      }
      else{
        setAlerta('No se encontró código de seguimiento')
      }
      setCargando(false)
    }
    catch (error) {
      setCargando(false)
      setAlerta('No se encontró código de seguimiento')
    }
    
  }

  return (
    <TrackingContext.Provider
      value={{
        alerta,
        setAlerta,
        busquedaTracking,
        listado,
        cargando
      }}
    >
      {children}
    </TrackingContext.Provider>
  );
};

export { TrackingProvider };

export default TrackingContext;
